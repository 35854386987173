import {getFormattedFullLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import sd from '../../../common/mobile-layout.scss'
import {useVisibilityStyles} from '../../../../../hooks/use-visibility-styles'
import s from './location.scss'

export const Location = ({event}: {event: wix.events.Event}) => {
  const {
    allBreakpoints: {isListLocationEnabled},
  } = useVisibilityStyles()
  if (!isListLocationEnabled()) {
    return null
  }
  return (
    <div className={classNames(s.location, sd.eventNameFont, sd.eventNameColor)} data-hook="location">
      {getFormattedFullLocation(event)}
    </div>
  )
}
