import {getFormattedLocation} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import sc from '../classes.scss'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import s from './short-date-location.scss'
import {ShortDateLocationProps} from '.'

export const ShortDateLocation = ({event}: ShortDateLocationProps) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  const {
    allBreakpoints: {isListVenueNameEnabled, isListDateEnabled},
  } = useVisibilityStyles()
  const venueVisible = isListVenueNameEnabled()
  const dateVisible = isListDateEnabled()

  return (
    <div className={classNames(s.container, sc.textLineHeight, s.alignment)} data-hook="ev-short-date-location">
      {dateVisible ? (
        <div className={s.date} data-hook="short-date">
          {shortStartDate}
        </div>
      ) : null}
      <div className={s.divider} />
      {venueVisible ? (
        <div className={s.location} data-hook="short-location">
          {getFormattedLocation(event)}
        </div>
      ) : null}
    </div>
  )
}
