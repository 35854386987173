import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {hasImage} from '@wix/wix-events-commons-statics'
import {EventImage} from '../../../event-image'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './image.scss'
import {ImageProps} from '.'

export const Image = ({event}: ImageProps) => {
  const {
    allBreakpoints: {isListImageEnabled},
  } = useVisibilityStyles()
  if (!isListImageEnabled()) {
    return null
  }
  return (
    <div data-hook={hasImage(event) ? DH.listImage : DH.listImagePlaceholder} className={s.image}>
      <EventImage event={event} backgroundFallback />
    </div>
  )
}
