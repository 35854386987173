import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import sc from '../../../classes.scss'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import s from './date.scss'
import {DateProps} from '.'

export const Date = ({t, customColorClass = '', scheduleTbd, event}: DateProps) => {
  const {
    allBreakpoints: {isListDateEnabled},
  } = useVisibilityStyles()
  const {weekDay, monthDay, month} = useEventDateInformation(event.id)
  const eventDateInfo = scheduleTbd ? t('listDateTbd') : `${weekDay} ${monthDay} ${month}`

  if (!isListDateEnabled()) {
    return null
  }

  return (
    <div
      className={classNames(s.root, s.listHeaderTitle, customColorClass || s.color)}
      style={{minWidth: 108}}
      data-hook={scheduleTbd ? 'ev-date-tbd' : 'ev-date'}
    >
      <span className={s.srOnly} aria-hidden={scheduleTbd ? true : false}>
        {eventDateInfo}
      </span>
      {scheduleTbd ? (
        <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-tbd-text">
          {t('listDateTbd')}
        </div>
      ) : (
        <>
          <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-month-day" aria-hidden="true">
            {monthDay}
          </div>
          <div className={classNames(sc.textNoMargin, s.weekAndMonth)}>
            <div
              className={s.weekDay}
              style={customColorClass ? null : {opacity: 0.5}}
              data-hook="ev-date-weekday"
              aria-hidden="true"
            >
              {weekDay}
            </div>
            <div className={s.month} data-hook="ev-date-month" aria-hidden="true">
              {month}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
